@import './fonts';

html {
  padding: 0;
  margin: 0;
  font-family: 'soehne-web', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  height: 100%;
}

body, #__next {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  overflow: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

textarea, textarea:focus, textarea:active,
input, input:focus, input:active,
button, button:focus, button:active {
  outline: initial;
}

.grid-auto-rows {
  grid-auto-rows: min-content; 
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.empty-wishlist {
  /* background-color: red; */
  background: url("/empty-wishlist.png") center center/contain no-repeat;

  position: relative;

  @media (max-width: 800px) {
    background-size: auto;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    width: 400px;
    height: 400px;
    border-radius: 100%;

    filter: blur(60px);

    background-color: #fff;

    // z-index: -1;
  }
}

@media (max-width: 1023px) {
  .pb-1-3 {
    padding-bottom: 33.33%;
  }
}

.react-datepicker-wrapper{
  width: 100%;
  height: 100%;

  input {
    background-color: transparent;
  }


}