@font-face {
  font-family: 'soehne-web';
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/soehne-web-halbfett.woff2) format('woff2'),
       url(/fonts/soehne-web-halbfett.woff) format('woff'),
       url(/fonts/soehne-web-halbfett.ttf) format('truetype');
}

@font-face {
  font-family: 'soehne-web';
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/soehne-web-kraftig.woff2) format('woff2'),
       url(/fonts/soehne-web-kraftig.woff) format('woff'),
       url(/fonts/soehne-web-kraftig.ttf) format('truetype');
}

@font-face {
  font-family: 'soehne-web';
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/soehne-web-buch.otf) format('opentype');
}


@font-face {
  font-family: 'soehne-web';
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/Sohne-Leicht.otf) format('opentype');
}
